<template>
  <div class="CloudHospital-container">
    <div class="page-outter">
      <div align="center">
        <img
          class="c-c-title"
          v-lazy="require('../../../assets/images/yun.png')"
        />
        <div class="c-c-line"></div>
        <div class="c-c-d1">
          <span class="s1">九医云医院</span>
          <span class="s2">重构医疗服务场景，推动医疗资源精准匹配</span>
        </div>
        <div class="c-c-d2">
          <ul>
            <li
              v-for="(item,index) in tdatas"
              :key="index"
            >
              <div class="c-c-d2-d1">
                <img v-lazy="item.icon" />
              </div>
              <span class="c-c-d2-s1">{{item.name}}</span>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CloudHospital",
  data() {
    return {
      tdatas: [
        {
          icon: require("../../../assets/images/s-1.png"),
          name: "个人健康管理",
        },
        {
          icon: require("../../../assets/images/s-2.png"),
          name: "在线诊疗服务",
        },
        {
          icon: require("../../../assets/images/s-3.png"),
          name: "名医会诊服务",
        },
        {
          icon: require("../../../assets/images/s-4.png"),
          name: "家庭医生服务",
        },
        {
          icon: require("../../../assets/images/s-5.png"),
          name: "社区健康服务",
        },
        {
          icon: require("../../../assets/images/s-6.png"),
          name: "电子病历服务",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.CloudHospital-container {
  width: 100%;
  border-top: 1px solid #fff;
  background: #fff;
  .c-c-title {
    margin-top: 2.4rem;
    width: 7rem;
  }
  .c-c-line {
    width: 1.1rem;
    height: 0.1rem;
    background: #6aa5ff;
    margin-top: 1rem;
  }
  .c-c-d1 {
    margin-top: 1rem;
    .s1 {
      font-size: 0.8rem;
      display: block;
      font-weight: 600;
    }
    .s2 {
      display: block;
      font-size: 0.4rem;
      color: #aaaaaa;
      margin-top: 0.3rem;
    }
  }
  .c-c-d2 {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 2.4rem;
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      margin-left: -2rem;
      li {
        float: left;
        margin-left: 2rem;
        .c-c-d2-d1 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.6rem;
          height: 2.6rem;
          border-radius: 120px;
          background: #6aa5ff;
          img {
            width: 1.6rem;
          }
        }
        .c-c-d2-s1 {
          font-size: 0.4rem;
          color: #6aa5ff;
        }
      }
    }
  }
}
</style>